import { cloneDeep, get, forOwn } from 'lodash';

export interface LegalBasis {
  title: string;
  description: string;
  attributes: string[];
  name: string;
  type: string;
}

export interface Attribute {
  prefixedKey: string;
  key: string;
  legalBases: string[];
}

const PREFIX = 'Members.';

export class LegalBasesAttributes {
  attributes: Attribute[];
  legalBases: LegalBasis[];

  constructor(legalBases: LegalBasis[]) {
    this.legalBases = cloneDeep(legalBases);
  }

  buildAttributes(attributesToIgnore: string[] = []) {
    this.attributes = [];

    this.legalBases.forEach(legalBasis => {
      legalBasis.attributes
        .filter(attr => attr.includes(PREFIX))
        .filter(attr => !attributesToIgnore.includes(attr.split('.').pop()))
        .forEach((attribute: string) => {

          const buildedAttribute = this.attributes.find(attr => attr.prefixedKey === attribute);

          if (!buildedAttribute) {
            this.attributes.push({
              prefixedKey: attribute,
              key: attribute.replace(PREFIX, ''),
              legalBases: [legalBasis.name]
            });
            return;
          }

          if (!buildedAttribute.legalBases.includes(legalBasis.name)) {
            buildedAttribute.legalBases.push(legalBasis.name);
            const index = this.attributes.findIndex(attr => attr.prefixedKey === buildedAttribute.prefixedKey);
            this.attributes[index] = buildedAttribute;
          }
        });
    });

    this.legalBases = this.legalBases.map(legalBasis => {
      legalBasis.attributes = legalBasis.attributes
        .filter(attr => attr.includes(PREFIX))
        .filter(attr => !attributesToIgnore.includes(attr.split('.').pop()))
        .map(attr => attr.replace(PREFIX, ''));
      return legalBasis;
    });

    return this;
  }

  validateTypedConsentedList(key: string, typeKey: string, previousList: Array<any> = [], list: Array<any> = [],
    consents: { [key: string]: boolean } = {}, attributesConfig: Attribute[] = this.attributes) {

    let missingConsents = [];
    const typeAttributesConfig = {};

    const regex = new RegExp(`\\[${typeKey}=['|"]([\\S]+)['|"]\\]\.([\\S]+)`, 'g');
    attributesConfig
      .filter(attrConfig => attrConfig.key && attrConfig.key.includes(`${key}[`))
      .forEach(attrConfig => {
        regex.lastIndex = 0;
        const match = regex.exec(attrConfig.key);
        const type = get(match, [1]);
        const subKey = get(match, [2]);
        if (type && subKey) {
          attrConfig.key = subKey;
          typeAttributesConfig[type] = (typeAttributesConfig[type] || []).concat(attrConfig);
        }
      });

    forOwn(typeAttributesConfig, (arrayAttributesConfig, type) => {
      const typedValues = list.filter(item => item[typeKey] === type);
      const previousTypedValues = previousList.filter(item => item[typeKey] === type);

      const mergedValue = this.mergeValues(typedValues);
      const mergedPreviousValue = this.mergeValues(previousTypedValues);

      missingConsents = missingConsents.concat(
        this.validateConsentedData(mergedPreviousValue, mergedValue, consents, arrayAttributesConfig)
      );
    });

    return missingConsents;
  }

  validateConsentedData(previousData: any = {}, data: any = {}, consents: { [key: string]: boolean } = {},
    attributesConfig: Attribute[] = this.attributes) {

    let missingConsents = [];
    Object.keys(data).forEach(key => {
      const value = data[key];
      // If values is not filled, it doesn't need a consent
      if (value === '' || value === undefined || value === null || value === []) {
        return;
      }

      // Get previous saved value
      const previousValue = previousData[key];
      if (!Array.isArray(value)) {
        // If previous value was already filled
        if (previousValue !== '' && previousValue !== undefined && previousValue !== null) {
          return;
        }

        // Identify attribute configuration
        const attrConfig = attributesConfig.find(attr => attr.key === key);

        // If there is a configuration, it means that the attribute is under a consent
        if (attrConfig) {
          // Verify if at least one consent is granted
          missingConsents = missingConsents.concat(this.getMissingConsents(attrConfig, consents));
        }

        return;
      }

      // Remove part of parent attribute from key.
      // E.g.: emailAddresses[].emailId => emailId
      const arrayAttributesConfig = attributesConfig
        .filter(attr => attr.key.includes(`${key}[].`))
        .map(attr => {
          attr.key = attr.key.replace(`${key}[].`, '');
          return attr;
        });

      // If value is an array, iterate over each item and merge values
      const mergedValue = this.mergeValues(value);
      const mergedPreviousValue = this.mergeValues(previousValue);

      missingConsents = missingConsents.concat(
        this.validateConsentedData(mergedPreviousValue, mergedValue, consents, arrayAttributesConfig)
      );
    });

    return [...new Set(missingConsents)];
  }

  private mergeValues(value: any[] = []) {
    return value.reduce((result, itemValue) => {
      Object.keys(itemValue).forEach(itemKey => {
        result[itemKey] = result[itemKey] || itemValue[itemKey];
      });
      return result;
    }, {});
  }

  private getMissingConsents(attrConfig: Attribute, consents: { [key: string]: boolean; }) {
    let missingConsents = [];
    const isGranted = attrConfig.legalBases.some(legalBasis => consents[legalBasis]);
    if (!isGranted) {
      // Get legal bases title
      missingConsents = this.legalBases.reduce((result, legalBasisConfig) => {
        if (attrConfig.legalBases.includes(legalBasisConfig.name)) {
          result.push(legalBasisConfig.title);
        }
        return result;
      }, []);
    }
    return missingConsents;
  }
}

