import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MembersService } from '../shared/members.service';
import { Member, PrimaryIndicator, MemberIdentifier } from '../shared/members.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { searchTypeValidator } from '../shared/validators/search-type.directive';
import { ToolbarService } from 'src/app/core/toolbar/toolbar.service';
import { NotificationService } from 'src/app/core/notification.service';

export interface MemberDisplayData {
  kocid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  socialId?: string;
}

@Component({
  selector: 'app-members-finder',
  templateUrl: './members-finder.component.html',
  styleUrls: ['./members-finder.component.scss']
})
export class MembersFinderComponent implements OnInit, OnDestroy {
  resultMessage: string;

  // Table
  get showResult() {
    return this.tableDataSource.length > 0;
  }

  displayedColumns: string[] = ['firstName', 'lastName', 'emailAddress', 'phoneNumber', 'socialId', 'action'];
  tableDataSource: MemberDisplayData[] = [];

  // Form components
  searchForm: FormGroup;
  email: FormControl;
  phoneNumber: FormControl;
  socialDomain: FormControl;
  socialId: FormControl;
  searchType: FormControl;

  constructor(private membersService: MembersService, private toolbarService: ToolbarService,
    private router: Router, private route: ActivatedRoute, private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.createFormControls();
    this.createFormGroup();
    this.resultMessage = '';
  }

  ngOnDestroy() { }

  createFormControls(): void {
    this.email = new FormControl('');
    this.phoneNumber = new FormControl('');
    this.socialDomain = new FormControl('');
    this.socialId = new FormControl('');
    this.searchType = new FormControl('email');
  }

  createFormGroup(): void {
    this.searchForm = new FormGroup({
      email: this.email,
      phoneNumber: this.phoneNumber,
      socialDomain: this.socialDomain,
      socialId: this.socialId,
      searchType: this.searchType,
    }, { validators: searchTypeValidator });
  }

  clearControls() {
    this.email.setValue('');
    this.phoneNumber.setValue('');
    this.socialDomain.setValue('');
    this.socialId.setValue('');
  }

  search() {
    this.resultMessage = '';
    if (!this.email.value && !this.phoneNumber.value
      && !this.socialDomain.value && !this.socialId.value) { return; }

    const filters = [];
    const searchValues = [];

    if (this.email.value) {
      searchValues.push(this.email.value);
      filters.push(this.membersService.buildFilterByEmail(this.email.value));
    }
    if (this.phoneNumber.value) {
      searchValues.push(this.phoneNumber.value);
      filters.push(this.membersService.buildFilterByPhone(this.phoneNumber.value));
    }
    if (this.socialDomain.value && this.socialId.value) {
      searchValues.push(`[${this.socialDomain.value}] ${this.socialId.value}`);
      filters.push(this.membersService.buildFilterBySocial(this.socialDomain.value, this.socialId.value));
    }

    this.toolbarService.isLoading(true);
    this.membersService.getMembers(filters)
      .subscribe(
        (data: Member[]) => {
          const searchValuesText = searchValues.join(' and ');
          if (data.length > 0) {
            this.convertToDataSource(data);
            this.resultMessage = `Search results by "${searchValuesText}"`;
          } else {
            this.tableDataSource = [];
            this.resultMessage = `No results found for "${searchValuesText}"`;
          }
        },
        error => {
          this.tableDataSource = [];
          this.toolbarService.isLoading(false);
          throw error;
        },
        () => {
          this.toolbarService.isLoading(false);
        });
  }

  manage(kocid: string) {
    if (!kocid) {
      this.notificationService.showMessage('Invalid operation: missing KOCID');
      return;
    }
    this.router.navigate([kocid], { relativeTo: this.route });
  }

  private convertToDataSource(members: Member[]) {
    this.tableDataSource = members.map((member) => {
      const phoneInfo = member.phoneNumbers
        .find(phone => phone.primaryIndicator === PrimaryIndicator.Y);
      const phoneNumber = phoneInfo && `${phoneInfo.countryCode}-${phoneInfo.phoneNumber}`;

      const defaultSocialId = member.socialDomain
        && member.socialDomain[0]
        && member.socialDomain[0].identifier;
      const socialDomain = member.socialDomain
        .find(social => social.identifier === this.socialId.value);
      let socialId = socialDomain && socialDomain.identifier;
      socialId = socialId || defaultSocialId;

      const externalIndentifier = member.memberIdentifiers
        .find(item => item.name === 'externalId');
      const kocid = externalIndentifier && externalIndentifier.value;

      const dataSource: MemberDisplayData = {
        kocid,
        socialId,
        firstName: member.firstName,
        lastName: member.lastName,
        email: member.emailAddress,
        phoneNumber: phoneNumber,
      };

      return dataSource;
    });
  }
}
