import { ValidatorFn, ValidationErrors, FormControl } from '@angular/forms';

// tslint:disable-next-line: max-line-length
const REGEX = /^['_A-Za-z0-9-&+]+(\.['_A-Za-z0-9-&+]+)*[.]{0,1}@([A-Za-z0-9-])+(\.[A-Za-z0-9-]+)*((\.[A-Za-z0-9]{2,})|(\.[A-Za-z0-9]{2,}\.[A-Za-z0-9]{2,}))$/;

export const emailValidator: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  if (!control.value) {
    return null;
  }
  return REGEX.test(control.value) ? null : { emailValidator: 'Invalid email format' };
};

