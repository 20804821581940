import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './core/page-not-found.component';
import { LogoutComponent } from './logout/logout.component';
import { ErrorComponent } from './core/error/error.component';

const appRoutes: Routes = [
    { path: '', redirectTo: '/members', pathMatch: 'full' },
    { path: 'logout', component: LogoutComponent },
    { path: 'error', component: ErrorComponent },
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
        RouterModule.forRoot(
            appRoutes,
            {
                useHash: true,
                initialNavigation: false,
                enableTracing: false // <-- debugging purposes only
            }
        )
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
