import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';

export const searchTypeValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
    const searchType = control.get('searchType');
    const email = control.get('email');
    const phoneNumber = control.get('phoneNumber');
    const socialDomain = control.get('socialDomain');
    const socialId = control.get('socialId');

    if (searchType.value === 'email') {
        return email && email.value ? null : { searchTypeValidator: true };
    }

    if (searchType.value === 'phoneNumber') {
        return phoneNumber && phoneNumber.value ? null : { searchTypeValidator: true };
    }

    if (searchType.value === 'socialDomain') {
        return socialDomain && socialId && socialDomain.value && socialId.value
            ? null
            : { searchTypeValidator: true };
    }

    return { searchTypeValidator: true };
};
