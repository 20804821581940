import { Injectable } from '@angular/core';
import { BaseControl } from './controls/base-control';
import { FormControl, Validators, FormGroup, FormArray, ValidatorFn } from '@angular/forms';
import { ControlType } from './controls/base-control';
import { GroupControl } from './controls/group-control';
import { CheckboxControl } from './controls/checkbox-control';

@Injectable({
  providedIn: 'root'
})
export class DynamicFormControlService {

  constructor() { }

  toFormGroup(controls: BaseControl<any>[] = [], data: any = {}) {
    const group: any = {};

    controls.forEach(control => {
      const value = data[control.key];
      if (control.controlType === ControlType.Array) {
        const groupControl = control.controls && control.controls[0];
        group[control.key] = this.toFormArray(groupControl, value, control.validators);
      } else {
        group[control.key] = control.required ?
          new FormControl({ value: value || '', disabled: control.readOnly }, [...control.validators, Validators.required])
          : new FormControl({ value: value || '', disabled: control.readOnly }, control.validators);

        if (control.controlType === ControlType.Datepicker) {
          const formControl = group[control.key] as FormControl;
          const isValidDate = this.isValidDate(value);
          const formattedValue = (value && isValidDate) ? value.replace(' ', 'T') : null;
          formControl.setValue(formattedValue);
        }

        if (control.controlType === ControlType.DateCreation) {
          const formControl = group[control.key] as FormControl;
          formControl.setValue( value ? new Date(value).toUTCString() : null);          
        }

        if (control.controlType === ControlType.Checkbox) {
          group[control.key] = control.required ?
            new FormControl({ value: !!value, disabled: control.readOnly }, Validators.required)
            : new FormControl({ value: !!value, disabled: control.readOnly });
        }
      }
    });

    return new FormGroup(group);
  }

  toFormArray(groupControl: BaseControl<string>, data: any[] = [], validators: ValidatorFn[] = []) {
    const formGroups: FormGroup[] = [];
    data.forEach(item => {
      formGroups.push(this.toFormGroup(groupControl.controls, item));
    });

    const formArray = new FormArray(formGroups);

    if (validators.length > 0) {
      formArray.setValidators(validators);
    }

    return formArray;
  }

  isValidDate(dateString: any): boolean {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime());
  }

}
