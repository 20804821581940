import { BaseControl, ControlType } from './base-control';
import { GroupControl } from './group-control';
import { ValidatorFn } from '@angular/forms';

export class ArrayControl extends BaseControl<any> {
    controlType = 'array';
    controls: GroupControl[];
    idKey?: string;

    constructor(options: {} = {}, controls: GroupControl[] = []) {
      super(options, controls);
      this.controls = controls;
      this.idKey = options['idKey'] || null;
    }
  }
