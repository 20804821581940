import { NgModule, ModuleWithProviders, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { PageNotFoundComponent } from './page-not-found.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors';
import { OAuthModule, AuthConfig, NullValidationHandler, ValidationHandler, OAuthStorage } from 'angular-oauth2-oidc';
import { AuthService } from './auth/auth.service';
import { authConfig } from './auth/auth-config';
import { AuthGuard } from './auth/auth.guard';
import { GlobalErrorHandler } from './global-error-handler';
import { LogoutDialogComponent } from '../logout/logout-dialog.component';
import { MatDialogModule } from '@angular/material';
import { ErrorComponent } from './error/error.component';

// We need a factory since localStorage is not available at AOT build time
export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  imports: [
    MatToolbarModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    MatProgressBarModule,
    MatDialogModule,
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuard,
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  declarations: [PageNotFoundComponent, ToolbarComponent, LogoutDialogComponent, ErrorComponent],
  entryComponents: [LogoutDialogComponent],
  exports: [PageNotFoundComponent, ToolbarComponent, HttpClientModule]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AuthConfig, useValue: authConfig },
        { provide: ValidationHandler, useClass: NullValidationHandler },
        { provide: OAuthStorage, useFactory: storageFactory },
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
