import { BaseControl } from './base-control';

export class TextBoxControlDateCreation extends BaseControl<string> {
  controlType = 'datecreation';
  options: { key: string, value: string }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.value = options['value'] ?
        new Date((options['value']).replace(' ', 'T')).toUTCString()
        : '';
  }
}
