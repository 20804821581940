import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BaseControl } from '../controls/base-control';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dynamic-field',
  templateUrl: './dynamic-form-field.component.html',
  styleUrls: ['./dynamic-form-field.component.scss']
})
export class DynamicFormFieldComponent implements OnInit {
  @Input() control: BaseControl<any>;
  @Input() form: FormGroup;
  @Input() customHint: string;
  @Output() typedHintChanged = new EventEmitter<string>();
  get isValid() { return this.form.controls[this.control.key].valid; }
  get hint() { return this.customHint || this.control.hint; }

  constructor() { }

  ngOnInit() {
    this.notifyTypeSelectionChange(this.form.controls[this.control.key].value);
  }

  notifyTypeSelectionChange(value) {
    if (this.control.canTriggerHintDef) {
      this.typedHintChanged.emit(value);
    }
  }

  getErrorMessage() {
    if (!this.form.controls[this.control.key].errors) {
      return;
    }
    const [key, value] = Object.entries(this.form.controls[this.control.key].errors)[0];
    return value;
  }

  formatDate(event) {
    this.form.controls[this.control.key].setValue(event.value.format('YYYY-MM-DD HH:mm:ss.S'));
  }
}
