import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { STS } from 'aws-sdk';
import { AuthService } from '../auth/auth.service';
import * as moment from 'moment';
import { LogoutDialogComponent } from '../../logout/logout-dialog.component';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StsService {
  region: string;
  provider: string;
  roleArn: string;
  roleSessionName: string;
  durationSeconds: number;

  private hasCredentialsLoadedSubject$ = new ReplaySubject<boolean>();
  public hasCredentialsLoaded$ = this.hasCredentialsLoadedSubject$.asObservable();

  constructor(private authService: AuthService, private dialog: MatDialog) {
    this.region = environment.sts.region;
    this.roleArn = environment.sts.roleArn;
    this.roleSessionName = environment.sts.roleSessionName;
    this.durationSeconds = environment.sts.durationSeconds;
    this.provider = environment.azureAd.provider;

    this.hasCredentialsLoadedSubject$.next(this.hasCredentialsLoaded());

    this.authService.hasTokenReceived$.subscribe(_ => {
      this.refreshCredentials()
        .then(() => {
          this.hasCredentialsLoadedSubject$.next(this.hasCredentialsLoaded());
        });
    });
  }

  refreshCredentials(): Promise<void> {
    const params: any = {
      RoleArn: this.roleArn,
      RoleSessionName: this.roleSessionName,
      DurationSeconds: this.durationSeconds,
      WebIdentityToken: this.authService.idToken
    };

    const sts = new STS({ region: this.region });
    return sts.assumeRoleWithWebIdentity(params).promise()
      .then((data: any) => {
        this.saveCredentials(
          data.Credentials.AccessKeyId,
          data.Credentials.SecretAccessKey,
          data.Credentials.SessionToken,
          data.Credentials.Expiration,
        );
      });
  }

  saveCredentials(accessKeyId: string, secretAccessKey: string, sessionToken: string, expiration: Date) {
    localStorage.setItem('access_key_id', accessKeyId);
    localStorage.setItem('secret_access_key', secretAccessKey);
    localStorage.setItem('aws_session_token', sessionToken);

    const expireTime = moment(expiration).format('x');
    localStorage.setItem('sts_expiration', expireTime);

    this.renewLastInteraction();
  }

  finishSession() {
    localStorage.removeItem('access_key_id');
    localStorage.removeItem('secret_access_key');
    localStorage.removeItem('aws_session_token');
    localStorage.removeItem('sts_expiration');
    localStorage.removeItem('aws_last_interation');
  }

  renewLastInteraction() {
    const lastInteractionTime = moment().format('x');
    localStorage.setItem('aws_last_interation', lastInteractionTime);
  }

  hasValidLastInteraction() {
    const lastInteractionString = localStorage.getItem('aws_last_interation');

    if (!lastInteractionString) { return false; }

    const lastInteraction = moment(Number(lastInteractionString));
    return lastInteraction.add(30, 'minutes').isSameOrAfter(moment());
  }

  hasValidSession() {
    const stsExpirationString = localStorage.getItem('sts_expiration');

    if (!stsExpirationString) { return false; }

    const stsExpiration = moment(Number(stsExpirationString));
    return stsExpiration.isSameOrAfter(moment());
  }

  hasCredentialsLoaded() {
    return !!localStorage.getItem('access_key_id')
      && !!localStorage.getItem('secret_access_key')
      && !!localStorage.getItem('aws_session_token')
      && !!localStorage.getItem('sts_expiration');
  }

  openDialog() {
    const dialogRef = this.dialog.open(LogoutDialogComponent, { disableClose: false });
    dialogRef.afterClosed().pipe(
      filter(doRefresh => doRefresh)
    ).subscribe(() => {
      this.authService.login();
    });
  }
}
