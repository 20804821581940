import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Member } from '../shared/members.interface';
import { BehaviorSubject, Subscription, pipe, throwError } from 'rxjs';
import { MembersFormControlService } from '../shared/members-form-control.service';
import { BaseControl } from '../../shared/dynamic-form/controls/base-control';
import { MembersService, MyDataRequestStatus } from '../shared/members.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import {
  ConfirmationDialogComponent, ConfirmationDialogData
} from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import {
  MissingConsentsDialogComponent,
  MissingConsentsDialogData
} from 'src/app/shared/dialogs/missing-consents-dialog/missing-consents-dialog.component';
import { filter, tap, flatMap } from 'rxjs/operators';
import { ToolbarService } from 'src/app/core/toolbar/toolbar.service';
import { LegalBasesAttributes } from 'src/app/core/legal-bases/legal-bases-attributes';
import { LegalBasesService } from 'src/app/core/legal-bases/legal-bases.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';

@Component({
  selector: 'app-members-manager',
  templateUrl: './members-manager.component.html',
  styleUrls: ['./members-manager.component.scss']
})
export class MembersManagerComponent implements OnInit, OnDestroy {

  kocid: string;
  member: Member;
  legalBasesAttributes: LegalBasesAttributes;
  controls: BaseControl<any>[];
  // Progress bar
  loading$: BehaviorSubject<boolean>;

  showMyDataTitle = 'Show My Data';
  forgetMeTitle = 'Forget Me';
  saveTitle = 'Save';

  confirmShowMyData$: Subscription;
  confirmForgetMe$: Subscription;
  confirmUpdate$: Subscription;
  afterDismissed$: Subscription;

  consentsForm: FormGroup;
  preferencesForm: FormGroup;

  get disableActionButtons() {
    return !this.kocid;
  }

  constructor(private route: ActivatedRoute, private router: Router, private formControlService: MembersFormControlService,
    private membersService: MembersService, private dialog: MatDialog, private notificationService: NotificationService,
    private toolbarService: ToolbarService, private legalBasesService: LegalBasesService) { }

  ngOnInit() {
    this.route.data
      .subscribe((data: { member: Member }) => {
        this.member = data.member;
        const externalIdentifier = (this.member.memberIdentifiers || [])
          .find(memberIdentitfier => memberIdentitfier.name === 'externalId');
        this.kocid = externalIdentifier && externalIdentifier.value;
        // This code is more sensible since legalBasesAttributes needs idKeys from formControlService
        // and formControlService needs attributes from formControlService
        this.legalBasesAttributes = new LegalBasesAttributes(this.legalBasesService.legalBases)
          .buildAttributes(this.formControlService.idKeys);
        this.controls = this.formControlService
          .getProfileForm(
            this.legalBasesAttributes.attributes,
            this.legalBasesAttributes.legalBases,
            this.membersService.countries,
          );
        this.toolbarService.configureBackPath('/members');
        this.createConsentsForm();
        this.createPreferencesForm();
      });
  }

  ngOnDestroy() {
    this.notificationService.dismiss();

    if (this.confirmShowMyData$) { this.confirmShowMyData$.unsubscribe(); }

    if (this.confirmForgetMe$) { this.confirmForgetMe$.unsubscribe(); }

    if (this.confirmUpdate$) { this.confirmUpdate$.unsubscribe(); }

    if (this.afterDismissed$) { this.afterDismissed$.unsubscribe(); }
  }

  showMyData() {
    this.confirmShowMyData$ = this.openConfirmationDialog({})
      .pipe(
        tap(() => { this.toolbarService.isLoading(true); }),
        flatMap(() => this.membersService.requestMemberData(this.kocid))
      ).subscribe(
        (result: any) => {
          if (result.data.message === MyDataRequestStatus.Processing) {
            this.openSnackBar('Request successfully received and in progress. '
              + 'It may take a while. Once it\'s done, the next time you request '
              + 'the PDF file containing all the user data will be downloaded.', 'Ok');
          }

          if (result.data.message === MyDataRequestStatus.Ready) {
            window.open(result.data.fileUrl, '_blank');
          }
        },
        error => {
          this.toolbarService.isLoading(false);
          throw error;
        },
        () => {
          this.toolbarService.isLoading(false);
        });
  }

  forgetMe() {
    this.confirmForgetMe$ = this.openConfirmationDialog({}).pipe(
      tap(() => { this.toolbarService.isLoading(true); }),
      flatMap(() => this.membersService.forgetMember(this.kocid))
    ).subscribe(
      (result: any) => {
        this.openSnackBar('Request successfully received and in progress. '
          + 'It may take a while. Once it\'s done, all the Member consents will be revoked '
          + 'and member status will be inactive.', 'Ok', () => { window.location.reload(); });
      },
      error => {
        this.toolbarService.isLoading(false);
        throw error;
      },
      () => {
        this.toolbarService.isLoading(false);
      });
  }

  onSubmit(submittedData: { value: { [key: string]: any }, deletedItems: { [key: string]: any } }) {
   try{
    const submittedValue: { [key: string]: any } = submittedData.value;
    const { deletedItems } = submittedData;

    // At this point the form is valid (no invalid field, group or array)
    let missingConsents = this.legalBasesAttributes
      .validateConsentedData(this.member, submittedValue, this.consentsForm.value);

    // Implemented logic exclusively for addresses and phoneNumbers
    // Both have legal bases configuration for specific types
    const addressesMissingConsents = this.legalBasesAttributes.validateTypedConsentedList(
      'addresses', 'addressType', this.member.addresses, submittedValue.addresses, this.consentsForm.value);

    const phoneNumbersMissingConsents = this.legalBasesAttributes.validateTypedConsentedList(
      'phoneNumbers', 'phoneType', this.member.phoneNumbers, submittedValue.phoneNumbers, this.consentsForm.value);

    missingConsents = missingConsents.concat(addressesMissingConsents, phoneNumbersMissingConsents);

    if (Array.isArray(missingConsents) && missingConsents.length > 0) {
      this.openMissingConsentsDialog({ missingConsents });
      return;
    }

    const data: ConfirmationDialogData = {
      okButtonLabel: 'Proceed',
      cancelButtonLabel: 'Cancel'
    };

    if (this.membersService.hasRevokedConsents(this.member.consents, this.consentsForm.value)) {
      data.alert = 'One or more consent have been removed, if saved, some attributes might be removed.';
    }

    this.confirmUpdate$ = this.openConfirmationDialog(data).pipe(
      tap(() => { this.toolbarService.isLoading(true); }),
      flatMap(() => {
        const updatedData: Member =
          this.membersService.buildUpdatedData(this.consentsForm.value, submittedValue, this.member, deletedItems, this.preferencesForm.value);
        return this.membersService.updateMember(this.kocid, updatedData);
      })
    ).subscribe(
      (result: any) => {
        this.openSnackBar('Your request is being processed and may take a while to reflect the changes.',
          'View', () => { window.location.reload(); });
      },
      error => {
        this.toolbarService.isLoading(false);
        throw error;
      },
      () => {
        this.toolbarService.isLoading(false);
      });
   }catch(e){
      console.log(e);
   }

   
  }

  private createConsentsForm() {
    if (!this.legalBasesAttributes
      || !Array.isArray(this.legalBasesAttributes.legalBases)
      || this.legalBasesAttributes.legalBases.length === 0) {

      // Only instantiating so angular doesn't throw error
      this.consentsForm = new FormGroup({});

      this.openSnackBar(
        'No configured legal bases. Please try again later',
        'Ok', () => { this.router.navigate(['members']); });
      return;
    }

    this.consentsForm = new FormGroup({});

    this.legalBasesAttributes.legalBases.forEach(legalBasis => {
      const memberConsent = this.member.consents.find(consent => consent.name === legalBasis.name);
      const granted = memberConsent && memberConsent.granted;
      this.consentsForm.addControl(legalBasis.name, new FormControl(granted || false));
    });
  }

  private createPreferencesForm() {
    this.preferencesForm = new FormGroup({});
    if (this.member.communicationPreferences) {
      this.member.communicationPreferences.forEach(preference => {
        this.preferencesForm.addControl(preference.name, new FormControl(preference.granted));
      });
    }
  }

  private openSnackBar(message: string, action?: string, afterDismissedHandler?: Function) {
    const config: MatSnackBarConfig = {};
    if (!action) { config.duration = 3000; }

    // Using component from service because it's necessary to handle afterDismissed.
    // In the service is not possible because it's wrapper in NgZone.
    this.afterDismissed$ = this.notificationService.snackBar.open(message, action, config)
      .afterDismissed().subscribe(
        () => afterDismissedHandler && afterDismissedHandler()
      );
  }

  private openConfirmationDialog(data: ConfirmationDialogData) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, { data });
    return dialogRef.afterClosed().pipe(
      filter(confirmed => confirmed)
    );
  }

  private openMissingConsentsDialog(data: MissingConsentsDialogData) {
    this.dialog.open(MissingConsentsDialogComponent, { data });
  }
}
