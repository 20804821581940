export interface Member {
    memberId: number;
    created?: number;
    title?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    displayName?: string;
    alias?: string;
    suffix?: string;
    genderCode?: string;
    birthday?: string;
    languageCode?: string;
    country?: string;
    memberStatus?: string;
    addresses?: Address[];
    phoneNumbers?: PhoneNumber[];
    emailAddress?: string;
    smsNumbers?: SMSNumber[];
    memberIdentifiers?: MemberIdentifier[];
    socialDomain?: SocialDomain[];
    socialProfiles?: SocialProfile[];
    emailAddresses?: EmailAddress[];
    consents?: Consent[];
    communicationPreferences?: Preference[];
    experiences?: Experience[];
    memberType?: MemberType;
    uuid?: string;
    legalAcceptances?: any[]; // ??? Couldn't find any definition in CCNA documentation
}

export enum AddressType {
    Home = 'Home',
    Work = 'Work'
}

export interface Address {
    addressId?: number;
    addressType: AddressType;
    streetAddress1?: string;
    streetAddress2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
}

export enum PhoneType {
    Mobile = 'Mobile'
}

export enum PrimaryIndicator {
    Y = 'Y',
    N = 'N'
}

export interface PhoneNumber {
    phoneNumberId?: number;
    phoneType: PhoneType;
    phoneNumber?: string;
    primaryIndicator?: PrimaryIndicator;
    countryCode?: string;
}

export interface SMSNumber {
    smsNumber?: string;
    smsType?: string; // Not possible to identify from CCNS documentation
}

export interface MemberIdentifier {
    name?: string;
    value?: string;
}

export interface SocialDomain {
    profileId?: number;
    domain?: string;
    identifier?: string;
    userName?: '';
}

export interface SocialProfile {
    name?: string;
    value?: string;
}

export interface EmailAddress {
    emailUniqueId?: number;
    email?: string;
    primaryIndicator?: PrimaryIndicator;
}

export interface Consent {
    name?: string;
    granted?: boolean;
}

export interface Preference {
    name?: string;
    granted?: boolean;
}

export interface Experience {
    name?: string;
}

export enum MemberType {
    Individual = 'Individual',
    Organization = 'Organization'
}
