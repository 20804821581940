import { Injectable } from '@angular/core';
import { MembersService } from '../shared/members.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, EMPTY, of, from } from 'rxjs';
import { take, mergeMap, catchError, tap } from 'rxjs/operators';
import { ToolbarService } from 'src/app/core/toolbar/toolbar.service';
import { LegalBasesService } from 'src/app/core/legal-bases/legal-bases.service';
import { NotificationService } from 'src/app/core/notification.service';

const MEMBERS_PATH = '/members';

@Injectable({
  providedIn: 'root'
})
export class MembersManagerResolverService {
  constructor(private membersService: MembersService, private toolbarService: ToolbarService, private router: Router,
    private notificationService: NotificationService, private legalBasesService: LegalBasesService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const kocid = route.paramMap.get('kocid');
    const defaultErrorMessage = 'We\'re sorry. Something went wrong. Please try again later.';

    if (!kocid) {
      this.router.navigate([MEMBERS_PATH]);
      return EMPTY;
    }

    return from(this.handleResolve())
      .pipe(
        tap(_ => this.toolbarService.isLoading(true)),
        mergeMap(() => this.legalBasesService.loadLegalBases()),
        take(1),
        mergeMap(() => this.membersService.getCountries()),
        mergeMap(() => this.membersService.getMembers(
          [this.membersService.buildFilterByKOCID(kocid)]
        )),
        take(1),
        mergeMap(members => {
          this.toolbarService.isLoading(false);
          if (members && members[0]) {
            return of(members[0]);
          } else { // id not found
            this.router.navigate([MEMBERS_PATH]);
            this.notificationService.showMessage('Couldn\'t find member data');
            return EMPTY;
          }
        }),
        catchError(error => {
          this.toolbarService.isLoading(false);
          this.router.navigate([MEMBERS_PATH]);
          console.error(error.message);
          this.notificationService.showMessage(defaultErrorMessage);
          return EMPTY;
        })
      );
  }

  private async handleResolve() {
    await new Promise(resolve => setTimeout(resolve, 500));
  }
}
