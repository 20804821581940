import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, Event } from '@angular/router';
import { Observable } from 'rxjs';
import { ToolbarService } from './toolbar.service';
import { AuthService } from '../auth/auth.service';
import { StsService } from '../aws/sts.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  showLogout: Boolean = false;

  homeUrl = '/members';
  logoutUrl = '/logout';
  errorUrl = '/error';
  landingUrl = '/';
  backPath$: Observable<string>;
  loading$: Observable<boolean>;

  constructor(private router: Router, private toolbarService: ToolbarService,
    private authService: AuthService, private stsService: StsService) { }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.toolbarService.configureBackPath('');
        this.showLogout = event.url !== this.logoutUrl
          && event.url !== this.errorUrl;
      }
    });

    this.backPath$ = this.toolbarService.backPath$.asObservable();
    this.loading$ = this.toolbarService.loading$.asObservable();
  }

  logout() {
    this.stsService.finishSession();
    this.authService.logout();
  }
}
