import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { MembersRoutingModule } from './members-routing.module';
import { MembersFormControlService } from './shared/members-form-control.service';
import { MembersComponent } from './members.component';
import { MembersFinderComponent } from './members-finder/members-finder.component';
import { MembersManagerComponent } from './members-manager/members-manager.component';

@NgModule({
  declarations: [MembersComponent, MembersFinderComponent, MembersManagerComponent],
  imports: [
    CommonModule,
    MembersRoutingModule,
    SharedModule
  ],
  providers: [MembersFormControlService]
})
export class MembersModule { }
