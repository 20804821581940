import { BaseControl, ControlType } from './base-control';

export class GroupControl extends BaseControl<any> {
    controlType = 'group';
    controls: BaseControl<any>[];
    hints: {[key: string]: any};

    constructor(options: {} = {}, controls: BaseControl<any>[] = []) {
      super(options, controls);
      this.controls = controls;
      this.hints = options['hints'] || {};
    }
  }
