import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: `https://${environment.azureAd.provider}`,
  loginUrl: environment.azureAd.identityProviderUrl + environment.azureAd.loginPath,
  logoutUrl: window.location.origin + '/logout',
  clientId: environment.azureAd.clientId,
  redirectUri: window.location.origin + '',
  responseType: 'id_token',
  oidc: true,
  requestAccessToken: false,
  scope: 'openid',
  strictDiscoveryDocumentValidation: false,
  showDebugInformation: true
};
