/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AwsSignerInterceptor } from './aws-signer-interceptor';
import { StsInterceptor } from './sts-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: StsInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AwsSignerInterceptor, multi: true },
];
