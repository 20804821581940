import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of, from } from 'rxjs';
import { StsService } from '../aws/sts.service';
import { catchError, tap, filter, mergeMap } from 'rxjs/operators';

@Injectable()
export class StsInterceptor implements HttpInterceptor {

  constructor(private stsService: StsService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get('x-aws-signer')) {
      return next.handle(req);
    }

    // if sts token is expired and user didn't interact with backend for more than 30 minutes
    if (!this.stsService.hasValidSession() && !this.stsService.hasValidLastInteraction()) {
      return throwError(new HttpErrorResponse({ error: { status: 3 }, status: 403 }));
    }

    return from(this.handleRequest(req, next))
      .pipe(
        filter(event => event instanceof HttpResponse),
        tap(_ => this.stsService.renewLastInteraction()),
        catchError((httpErrorResponse: HttpErrorResponse) => {
          return throwError(httpErrorResponse);
        }));
  }

  private async handleRequest(req: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const transformedReq = req.clone();

    // if sts token is expired and user didn't interact with backend for more than 30 minutes
    if (!this.stsService.hasValidSession()) {
      // if user interacted with backend in the last 30 minutes
      // renew credentials
      await this.stsService.refreshCredentials();
    }

    // send cloned request with altered header to the next handler.
    return next.handle(transformedReq).toPromise();
  }
}
