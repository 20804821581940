import { Injectable } from '@angular/core';
import { BaseControl } from '../../shared/dynamic-form/controls/base-control';
import { TextboxControl } from '../../shared/dynamic-form/controls/textbox-control';
import { DropdownControl } from '../../shared/dynamic-form/controls/dropdown-control';
import { DatepickerControl } from '../../shared/dynamic-form/controls/datepicker-control';
import { TextBoxControlDateCreation } from '../../shared/dynamic-form/controls/datecreation-control';
import { ArrayControl } from '../../shared/dynamic-form/controls/array-control';
import { GroupControl } from '../../shared/dynamic-form/controls/group-control';
import { primaryIndicatorValidator } from './validators/primary-indicator.directive';
import { uniqueValidator } from './validators/unique.directive';
import { emailValidator } from './validators/email.directive';
import { Attribute } from '../../core/legal-bases/legal-bases-attributes';
import { LegalBases } from '../../core/legal-bases/legal-bases.interface';
import { get, set, forOwn } from 'lodash';

@Injectable()
export class MembersFormControlService {

  // Map of all keys that represents an Id
  // Id keys are not cleaned up
  idKeys = ['memberId', 'addressId', 'phoneNumberId', 'profileId', 'emailUniqueId'];

  constructor() { }

  getProfileForm(attributesConfig: Attribute[] = [], legalBasesConfig: LegalBases[] = [], countries: any[] = []) {
    const controls: BaseControl<any>[] = [
      new TextboxControl({
        key: 'memberId',
        label: 'Member Id',
        required: true,
        order: 1,
        readOnly: true,
      }),
      new TextboxControl({
        key: 'uuid',
        label: 'Janrain UUID',
        required: false,
        order: 2,
        readOnly: true,
      }),
      new TextBoxControlDateCreation({
        key: 'created',
        label: 'Date of Creation',
        required: true,
        order: 3,
        readOnly: true,
      }),
      new TextboxControl({
        key: 'memberStatus',
        label: 'Member Status',
        required: false,
        order: 4,
        readOnly: true,
      }),
      new DropdownControl({
        key: 'title',
        label: 'Title',
        required: false,
        order: 5,
        options: [
          { key: 'Master', value: 'Master' },
          { key: 'Mr', value: 'Mr' },
          { key: 'Miss', value: 'Miss' },
          { key: 'Mrs', value: 'Mrs' },
          { key: 'Ms', value: 'Ms' },
          { key: 'Mx', value: 'Mx' },
          { key: 'M', value: 'M' }
        ],
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'title'),
      }),
      new TextboxControl({
        key: 'firstName',
        label: 'First Name',
        required: false,
        order: 6,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'firstName'),
      }),
      new TextboxControl({
        key: 'middleName',
        label: 'Middle Name',
        required: false,
        order: 7,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'middleName'),
      }),
      new TextboxControl({
        key: 'lastName',
        label: 'Last Name',
        required: false,
        order: 8,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'lastName'),
      }),
      new TextboxControl({
        key: 'displayName',
        label: 'Display Name',
        required: false,
        order: 9,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'displayName'),
      }),
      new TextboxControl({
        key: 'alias',
        label: 'Alias',
        required: false,
        order: 10,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'alias'),
      }),
      new TextboxControl({
        key: 'suffix',
        label: 'Suffix',
        required: false,
        order: 11,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'suffix'),
      }),
      new DropdownControl({
        key: 'genderCode',
        label: 'Gender',
        required: false,
        order: 12,
        options: [
          { key: 'M', value: 'Male' },
          { key: 'F', value: 'Female' }
        ],
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'genderCode'),
      }),
      new DatepickerControl({
        key: 'birthday',
        label: 'Birthday',
        required: false,
        order: 13,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'birthday'),
      }),
      new TextboxControl({
        key: 'languageCode',
        label: 'Language Code',
        bottomhint: 'Language Code format example: en',
        required: false,
        order: 14,
        maxLength: 2,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'languageCode'),
      }),
      new DropdownControl({
        key: 'country',
        label: 'Country',
        required: false,
        order: 15,
        options: countries,
        hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'country'),
      }),
      new ArrayControl({
        key: 'addresses',
        label: 'Member Addresses',
        required: false,
        order: 16,
        idKey: 'addressId',
      }, [
        new GroupControl({
          key: '',
          required: false,
          hints: this.generateTypedHintsFrom(attributesConfig, legalBasesConfig, 'addresses', 'addressType'),
        }, [
          new TextboxControl({
            key: 'addressId',
            label: 'Id',
            required: false,
            order: 1,
            hidden: true,
          }),
          new DropdownControl({
            key: 'addressType',
            label: 'Type',
            required: false,
            order: 2,
            options: [
              { key: 'Home', value: 'Home' },
              { key: 'Work', value: 'Work' },
              { key: 'Shipping', value: 'Shipping' },
              { key: 'Mailing', value: 'Mailing' },
            ],
            canTriggerHintDef: true,
          }),
          new TextboxControl({
            key: 'streetAddress1',
            label: 'Address 1',
            required: false,
            order: 3,
          }),
          new TextboxControl({
            key: 'streetAddress2',
            label: 'Address 2',
            required: false,
            order: 4,
          }),
          new TextboxControl({
            key: 'city',
            label: 'City',
            required: false,
            order: 5,
          }),
          new TextboxControl({
            key: 'state',
            label: 'State',
            required: false,
            order: 6,
            maxLength: 2,
          }),
          new TextboxControl({
            key: 'postalCode',
            label: 'Postal Code',
            required: false,
            order: 7,
          }),
          new TextboxControl({
            key: 'country',
            label: 'Country',
            required: false,
            order: 8,
            maxLength: 2,
          }),
        ])
      ]),
      new ArrayControl({
        key: 'phoneNumbers',
        label: 'Member Phone Numbers',
        required: false,
        order: 17,
        idKey: 'phoneNumberId',
        validators: [primaryIndicatorValidator('phone number'), uniqueValidator(['phoneNumber'])],
      }, [
        new GroupControl({
          key: '',
          required: false,
          hints: this.generateTypedHintsFrom(attributesConfig, legalBasesConfig, 'phoneNumbers', 'phoneType'),
        }, [
          new TextboxControl({
            key: 'phoneNumberId',
            label: 'Id',
            required: false,
            order: 1,
            hidden: true,
          }),
          new DropdownControl({
            key: 'phoneType',
            label: 'Type',
            required: false,
            order: 2,
            options: [
              { key: 'Home', value: 'Home' },
              { key: 'Work', value: 'Work' },
              { key: 'Mobile', value: 'Mobile' },
            ],
            canTriggerHintDef: true,
          }),
          new TextboxControl({
            key: 'phoneNumber',
            label: 'Phone Number',
            required: false,
            order: 3,
            maxLength: 13,
          }),
          new DropdownControl({
            key: 'primaryIndicator',
            label: 'Primary Phone',
            required: false,
            order: 4,
            options: [
              { key: 'Y', value: 'Yes' },
              { key: 'N', value: 'No' },
            ],
          }),
          new TextboxControl({
            key: 'countryCode',
            label: 'Country Code',
            required: false,
            order: 5,
            maxLength: 3,
          }),
        ])
      ]),
      new ArrayControl({
        key: 'memberIdentifiers',
        label: 'Member Identifiers',
        required: false,
        order: 18,
        readOnly: true,
      }, [
        new GroupControl({
          key: '',
          required: false,
        }, [
          new TextboxControl({
            key: 'name',
            label: 'Name',
            required: false,
            order: 1,
            readOnly: true,
          }),
          new TextboxControl({
            key: 'value',
            label: 'Value',
            required: false,
            order: 2,
            readOnly: true,
          }),
        ]),
      ]),
      new ArrayControl({
        key: 'socialDomain',
        label: 'Member Social Domains',
        required: false,
        order: 19,
        idKey: 'profileId',
      }, [
        new GroupControl({
          key: '',
          required: false,
        }, [
          new TextboxControl({
            key: 'profileId',
            label: 'Id',
            required: false,
            order: 1,
            hidden: true,
          }),
          new TextboxControl({
            key: 'domain',
            label: 'Domain',
            required: false,
            order: 2,
            hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'socialDomain', 'domain'),
          }),
          new TextboxControl({
            key: 'identifier',
            label: 'Identifier',
            required: false,
            order: 3,
            hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'socialDomain', 'identifier'),
          }),
          new TextboxControl({
            key: 'userName',
            label: 'User Name',
            required: false,
            order: 4,
            hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'socialDomain', 'userName'),
          }),
        ]),
      ]),
      new ArrayControl({
        key: 'emailAddresses',
        label: 'Email Addresses',
        required: false,
        order: 20,
        idKey: 'emailUniqueId',
        validators: [primaryIndicatorValidator('email address'), uniqueValidator(['email'])]
      }, [
        new GroupControl({
          key: '',
          required: false,
        }, [
          new TextboxControl({
            key: 'emailUniqueId',
            label: 'Id',
            required: false,
            order: 1,
            hidden: true,
          }),
          new TextboxControl({
            key: 'email',
            label: 'Email',
            required: false,
            order: 2,
            validators: [emailValidator],
            hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'emailAddresses', 'email'),
          }),
          new DropdownControl({
            key: 'primaryIndicator',
            label: 'Primary Email',
            required: false,
            order: 3,
            options: [
              { key: 'Y', value: 'Yes' },
              { key: 'N', value: 'No' },
            ],
            hint: this.generateHintFrom(attributesConfig, legalBasesConfig, 'emailAddresses', 'primaryIndicator'),
          }),
        ]),
      ]),
      // Consents are added as a custom section
      // Experiences are Hidden
      // Legal Acceptances are not displayed
    ];

    return controls.sort((a, b) => a.order - b.order);
  }

  private generateHintText(legalBasesName: Array<string> = [], legalBasesConfig: LegalBases[] = []) {
    const legalBasesTitles = [];
    legalBasesName.forEach((legalBasisName) => {
      const legalBasisConfig = legalBasesConfig.find(config => config.name === legalBasisName);
      if (legalBasisConfig) {
        legalBasesTitles.push(legalBasisConfig.title);
      }
    });

    let hint = '';
    if (legalBasesTitles.length === 1) {
      hint = `This attribute requires ${legalBasesTitles[0]} consent.`;
    }

    if (legalBasesTitles.length > 1) {
      hint = `This attribute requires ${legalBasesTitles.join(' or ')} consents.`;
    }

    return hint;
  }

  private generateHintFrom(attributesConfig: Attribute[], legalBasesConfig: LegalBases[], mainKey: string, subKey?: string) {
    let searchKey = mainKey;
    if (subKey) {
      searchKey = `${mainKey}[].${subKey}`;
    }

    const searchedConfig = attributesConfig.find(config => config.key === searchKey);
    if (!searchedConfig) {
      return '';
    }

    return this.generateHintText(searchedConfig.legalBases, legalBasesConfig);
  }

  private generateTypedHintsFrom(attributesConfig: Attribute[] = [], legalBasesConfig: LegalBases[] = [], key: string, typeKey: string) {
    const typedConsents = {};
    const hints = {};

    // Identify consents of generic typed attributes
    const genericRegex = new RegExp(`${key}\\[\\]\.([\\S]+)`, 'g');
    attributesConfig
      .filter(attrConfig => attrConfig.key && attrConfig.key.includes(`${key}[]`))
      .forEach(attrConfig => {
        genericRegex.lastIndex = 0;
        const match = genericRegex.exec(attrConfig.key);
        const subKey = get(match, [1]);
        if (subKey) {
          // const hint = this.generateHintText(attrConfig.legalBases, legalBasesConfig);
          let legalBasesId = get(typedConsents, ['generic', subKey]);
          legalBasesId = [].concat(legalBasesId, attrConfig.legalBases);
          legalBasesId = [...new Set(legalBasesId)];
          set(typedConsents, ['generic', subKey], legalBasesId);
        }
      });

    // Identify consents of typed attributes
    const typedRegex = new RegExp(`${key}\\[${typeKey}=['|"]([\\S]+)['|"]\\]\.([\\S]+)`, 'g');
    attributesConfig
      .filter(attrConfig => attrConfig.key && attrConfig.key.includes(`${key}[`))
      .forEach(attrConfig => {
        typedRegex.lastIndex = 0;
        const match = typedRegex.exec(attrConfig.key);
        const type = get(match, [1]);
        const subKey = get(match, [2]);
        if (type && subKey) {
          // const hint = this.generateHintText(attrConfig.legalBases, legalBasesConfig);
          let legalBasesId = get(typedConsents, [type, subKey]);
          const genericLegalBasesId = get(typedConsents, ['generic', subKey]);
          legalBasesId = [].concat(legalBasesId, genericLegalBasesId, attrConfig.legalBases);
          legalBasesId = [...new Set(legalBasesId)];
          set(typedConsents, [type, subKey], legalBasesId);
        }
      });

    forOwn(typedConsents, (attributes, type) => {
      forOwn(attributes, (consents, attr) => {
        const hint = this.generateHintText(consents, legalBasesConfig);
        set(hints, [type, attr], hint);
      });
    });

    return hints;
  }
}
