import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';

export interface MissingConsentsDialogData {
  missingConsents: string[];
}

@Component({
  selector: 'app-missing-consents-dialog',
  templateUrl: './missing-consents-dialog.component.html',
  styleUrls: ['./missing-consents-dialog.component.scss']
})
export class MissingConsentsDialogComponent implements OnInit, AfterViewInit {

  text: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: MissingConsentsDialogData, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    const { missingConsents } = this.data;
    const consents = cloneDeep(missingConsents);
    if (consents.length === 1) {
      this.text = consents[0];
    } else {
      const lastMissingConsent = consents.pop();
      this.text = `${consents.map(consent => `<b>${consent}</b>`).join(', ')} and <b>${lastMissingConsent}</b>`;
    }
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

}
