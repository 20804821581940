import {
  Component, OnInit, Input, ChangeDetectionStrategy,
  AfterViewInit, ChangeDetectorRef, Output, EventEmitter, TemplateRef
} from '@angular/core';
import { BaseControl } from './controls/base-control';
import { FormGroup } from '@angular/forms';
import { DynamicFormControlService } from './dynamic-form-control.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit, AfterViewInit {
  @Input() controls: BaseControl<any>[] = [];
  @Input() data: any = {};
  @Input() customSection: TemplateRef<any>;
  @Output() submitForm = new EventEmitter<any>();
  form: FormGroup;
  deletedItems: { [key: string]: any[] } = {};

  constructor(private dfcs: DynamicFormControlService, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.form = this.dfcs.toFormGroup(this.controls, this.data);
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }

  onSubmit() {
    if (this.form.invalid) {
      this.scrollToError();
      return;
    }

    this.submitForm.emit({ value: cloneDeep(this.form.value), deletedItems: this.deletedItems });
  }

  storeDeletedItem(event: { key: string, value: any }) {
    if (!this.deletedItems[event.key]) {
      this.deletedItems[event.key] = [event.value];
    } else {
      this.deletedItems[event.key].push(event.value);
    }
  }

  private scrollToError() {
    setTimeout(() => {
      const toolbarElement = document.getElementById('app-toolbar');
      const formElement: Element = document.getElementsByTagName('app-dynamic-form').item(0);
      const groupErrorElements = formElement.getElementsByClassName('group-error-messages');
      const fieldErrorElements = Array.prototype.
        filter.call(
          formElement.getElementsByClassName('mat-form-field-wrapper'),
          (fieldElement: HTMLElement) => {
            const errorElements = fieldElement.getElementsByClassName('field-error-messages');
            return errorElements.length > 0;
          });

      let groupErrorsOffsetTop = [];
      if (groupErrorElements && groupErrorElements.length > 0) {
        groupErrorsOffsetTop = Array.prototype
          .map.call(groupErrorElements || [], groupErrorElement => groupErrorElement.offsetTop);
      }

      let fieldErrorsOffsetTop = [];
      if (fieldErrorElements && fieldErrorElements.length > 0) {
        fieldErrorsOffsetTop = Array.prototype
          .map.call(
            fieldErrorElements || [],
            (fieldErrorElement: HTMLElement) => window.pageYOffset + fieldErrorElement.getBoundingClientRect().top);
      }

      const errorsOffsetTop = groupErrorsOffsetTop.concat(fieldErrorsOffsetTop).sort();

      if (!errorsOffsetTop || errorsOffsetTop.length === 0) {
        return;
      }

      let additionalTopHeight = 0;
      if (toolbarElement) {
        additionalTopHeight = toolbarElement.offsetHeight;
      }


      window.scroll({ top: errorsOffsetTop[0] - additionalTopHeight, behavior: 'smooth' });
    });
  }
}
