// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  regionsJson: '',
  countriesJson: 'https://s3-ap-southeast-1.amazonaws.com/prod-apac-ap-southeast-1-s3-config/management-region.json',
  cdsAPI: {
    region: 'ap-southeast-1',
    baseUrl: 'https://dev.internal.cds-ap.citko.net',
    apiKey: 'Zhzs5PmFAEgoKAzmqABD4MIuDzKcJxN3MZlRJJlb',
  },
  azureAd: {
    // Identity Provider Url
    identityProviderUrl: 'https://login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c',
    // Identity Provider Name (Issuer: https://<provider>)
    provider: 'login.microsoftonline.com/548d26ab-8caa-49e1-97c2-a1b1a06cc39c/v2.0',
    // Login path of the Identity Provider
    loginPath: '/oauth2/v2.0/authorize',
    // Logout path of the Identity Provider
    logoutPath: '/oauth2/v2.0/endsession',
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'df251c91-498b-4f49-894a-cf36831d659d',
  },
  sts: {
    region: 'ap-southeast-1',
    roleArn: 'arn:aws:iam::561796161542:role/alpha-apac-ap-southeast-1-privacy-panel-auth-role',
    roleSessionName: 'azure_oidc_session',
    durationSeconds: 1800
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
