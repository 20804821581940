import { ValidatorFn, FormGroup, ValidationErrors, FormArray } from '@angular/forms';

export function primaryIndicatorValidator(itemDescription: string = 'item'): ValidatorFn {
    return (arrayControl: FormArray): ValidationErrors | null => {
        const primaryItems = arrayControl.controls
            .filter(
                (groupControl: FormGroup) => groupControl.controls['primaryIndicator']
                    && groupControl.controls['primaryIndicator'].value === 'Y'
            );

        return primaryItems.length > 1 ? { primaryIndicatorValidator: `There should be only one primary ${itemDescription}` } : null;
    };
}
