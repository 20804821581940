import { Injectable, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService, UrlHelperService } from 'angular-oauth2-oidc';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  logoutUrl = '/logout';

  private isDevMode = isDevMode();

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private hasTokenReceivedSubject$ = new ReplaySubject<boolean>();
  hasTokenReceived$ = this.hasTokenReceivedSubject$.asObservable();

  constructor(
    private oauthService: OAuthService, private router: Router, private urlHelper: UrlHelperService
  ) {
    if (this.isDevMode) {
      this.oauthService.events.subscribe(event => {
        if (event instanceof OAuthErrorEvent) {
          console.error(event);
        } else {
          console.warn(event);
        }
      });
    }

    // This is tricky, as it might cause race conditions (where id_token is set in another
    // tab before everything is said and done there.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'id_token' && event.key !== null) { return; }

      this.isAuthenticatedSubject$.next(this.oauthService.hasValidIdToken());
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidIdToken());
      });

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => {
        this.navigateToLogoutPage();
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.hasTokenReceivedSubject$.next(true));
  }

  runInitialLoginSequence(): Promise<void> {
    let queryString;
    if (location.hash) {
      if (location.hash.includes('error')) {
        this.router.navigate(['error']);
        return;
      }

      queryString = this.urlHelper.parseQueryString(location.hash.substr(1)) || {};
      if (this.isDevMode) {
        console.log('Encountered hash fragment, plotting as table...');
        console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
      }
    }

    return this.oauthService.loadDiscoveryDocument()
      .then(() => this.oauthService.tryLoginImplicitFlow())
      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          setTimeout(() => {
            this.router.navigate([this.oauthService.state]);
          }, 10);
        } else {
          this.router.navigateByUrl(location.hash.substr(1));
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  login(targetUrl?: string) {
    this.oauthService.initImplicitFlow(encodeURIComponent(targetUrl || this.router.url));
  }

  logout() {
    localStorage.clear();
    this.router.navigateByUrl(this.logoutUrl);
  }

  refresh() { this.oauthService.silentRefresh(); }

  hasValidToken() {
    return this.oauthService.hasValidIdToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  get accessToken() { return this.oauthService.getAccessToken(); }
  get identityClaims() { return this.oauthService.getIdentityClaims(); }
  get idToken() { return this.oauthService.getIdToken(); }

  private navigateToLogoutPage() {
    this.router.navigateByUrl(this.logoutUrl);
  }
}
