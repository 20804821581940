import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from './notification.service';
import { get } from 'lodash';
import { StsService } from './aws/sts.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  notifierService: NotificationService;
  stsService: StsService;

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector, private zone: NgZone) {
    this.notifierService = this.injector.get(NotificationService);
    this.stsService = this.injector.get(StsService);
  }

  handleError(error: Error | HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      this.zone.run(() => {
        this.handleHttpError(error);
      });
    } else {
      console.error(error);
    }
  }

  private handleHttpError(httpErrorResponse: HttpErrorResponse) {
    // Http Error
    const error = httpErrorResponse.error;

    if (error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.message);
      // return an observable error with a user-facing error message
      this.notifierService.showMessage('Network error. Please verify your internet connection.');
    } else {
      const defaultErrorMessage = 'We\'re sorry. Something went wrong. Please try again later.';
      const httpStatus = httpErrorResponse.status;
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${httpStatus}, body was: ${JSON.stringify(error)}`);

      if (httpStatus === 403) {
        this.stsService.finishSession();
        this.stsService.openDialog();
        return;
      }

      const errorStatus = get(error, 'status');

      switch (errorStatus) {
        case 1:
        case 2:
          const errorMessage = get(error, 'errors[0].message', defaultErrorMessage);
          this.notifierService.showMessage(errorMessage);
          break;
        default:
          this.notifierService.showMessage(defaultErrorMessage);
          break;
      }
    }
  }
}
