import { BaseControl } from './base-control';

export class DatepickerControl extends BaseControl<string> {
  controlType = 'datepicker';
  options: { key: string, value: string }[] = [];
  minData: Date = new Date(1900, 0, 1);
  maxDate: Date = new Date();

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.value = options['value'] ?
      new Date((options['value'] as string).replace(' ', 'T')).toISOString()
      : '';
  }
}
