import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SignatureV4, Headers } from 'signature-v4';

@Injectable({
  providedIn: 'root'
})
export class AwsSignerService {
  private region: string;
  private apiKey: string;
  private accessKey: string;
  private secretKey: string;
  private sessionToken: string;

  constructor() {
    this.region = environment.cdsAPI.region;
    this.apiKey = environment.cdsAPI.apiKey;
  }

  getAwsHeader(url, method, body?) {
    this.accessKey = localStorage.getItem('access_key_id');
    this.secretKey = localStorage.getItem('secret_access_key');
    this.sessionToken = localStorage.getItem('aws_session_token');

    const requestOptions = {
      url,
      method,
      payload: '',
      headers: new Headers({ 'Content-Type': 'application/json' })
    };

    if (body) {
      requestOptions.payload = JSON.stringify(body);
    }

    const signer = new SignatureV4(requestOptions, 'execute-api', this.region);
    const awsHeaders = signer.createHeaders(this.accessKey, this.secretKey, this.sessionToken);

    return {
      'x-api-key': this.apiKey,
      'X-Amz-Date': awsHeaders.get('X-Amz-Date'),
      'Authorization': awsHeaders.get('Authorization'),
      'X-Amz-Security-Token': awsHeaders.get('X-Amz-Security-Token'),
    };
  }
}
