import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(public snackBar: MatSnackBar) { }

  showMessage(message: string, action?: string) {
    const config: MatSnackBarConfig = {};
    if (!action) { config.duration = 3000; }

    return this.snackBar.open(message, action, config);
  }

  dismiss() {
    this.snackBar.dismiss();
  }
}
