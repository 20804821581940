import { ValidatorFn, FormGroup, ValidationErrors, FormArray } from '@angular/forms';

export function uniqueValidator(comparatorKeys: string[] = []): ValidatorFn {
  return (arrayControl: FormArray): ValidationErrors | null => {
    if (comparatorKeys.length === 0) {
      return null;
    }

    const hasDuplicate = comparatorKeys.some((comparatorKey) => {
      const values = arrayControl.controls
        .map((formGroup) => (formGroup as FormGroup).controls[comparatorKey].value)
        .filter(value => value);

      return values.length !== new Set(values).size;
    });

    return hasDuplicate ? { uniqueValidator: 'Duplicated values not allowed' } : null;
  };
}
