import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MembersComponent } from './members.component';
import { MembersFinderComponent } from './members-finder/members-finder.component';
import { MembersManagerComponent } from './members-manager/members-manager.component';
import { MembersManagerResolverService } from './members-manager/members-manager-resolver.service';
import { AuthGuard } from '../core/auth/auth.guard';

const routes: Routes = [
  {
    path: 'members', component: MembersComponent, canActivate: [AuthGuard], children: [
      { path: '', component: MembersFinderComponent },
      {
        path: ':kocid',
        component: MembersManagerComponent,
        resolve: {
          member: MembersManagerResolverService
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MembersRoutingModule { }
