import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GroupControl } from '../controls/group-control';
import { get } from 'lodash';

@Component({
  selector: 'app-dynamic-form-group',
  templateUrl: './dynamic-form-group.component.html',
  styleUrls: ['./dynamic-form-group.component.scss']
})
export class DynamicFormGroupComponent implements OnInit {
  @Input() groupControl: GroupControl;
  @Input() form: FormGroup;
  @Input() allowDeletion = true;
  @Output() deletedItem = new EventEmitter<any>();

  get controls() {
    return this.groupControl.controls;
  }

  hintType = '';

  constructor() { }

  ngOnInit() { }

  deleteItem() {
    this.deletedItem.emit(this.form.value);
  }

  setHintType(type) {
    this.hintType = type;
  }

  getCustomHint(key) {
    return get(this.groupControl.hints, [this.hintType, key])
      || get(this.groupControl.hints, ['generic', key], '');
  }
}
