import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { differenceWith, isEqual, findIndex, filter, cloneDeep, remove } from 'lodash';
import { Member, Consent, Preference } from './members.interface';
import { environment } from 'src/environments/environment';

export interface SearchFilter {
  searchParameterName: string;
  searchParameterValue: any[] | string | number;
}

export enum MyDataRequestStatus {
  Processing = 'Processing',
  Ready = 'Ready'
}

@Injectable({
  providedIn: 'root'
})
export class MembersService {
  private baseUrl: string;
  private defaultHeaders: any;

  // Fetched members
  members: Member[] = [];
  countries: any[] = [];

  constructor(private http: HttpClient) {
    this.baseUrl = environment.cdsAPI.baseUrl;
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-aws-signer': 'true'
    };
  }

  buildFilterByKOCID(kocid: string): SearchFilter {
    return {
      searchParameterName: 'KOCID',
      searchParameterValue: kocid
    };
  }

  buildFilterByPhone(phone: string): SearchFilter {
    var countryCode = null;
    var phoneNumber = null;
    if (phone.includes('-')) {
      [countryCode, phoneNumber] = phone.split('-');
    }
    else {
      phoneNumber = phone;
    }
    return {
      searchParameterName: 'phoneNumbers',
      searchParameterValue: [{ countryCode, phoneNumber }]
    };
  }

  buildFilterByEmail(email: string): SearchFilter {
    return {
      searchParameterName: 'emailAddress',
      searchParameterValue: email
    };
  }

  buildFilterBySocial(domain: string, identifier: string) {
    return {
      searchParameterName: 'socialDomain',
      searchParameterValue: [{ domain, identifier }]
    };
  }

  /**
   * Sends a request to ``POST /v2/consumersmapper/members/users/search`` API
   */
  getMembers(filters: SearchFilter[]): Observable<any> {
    const url = `${this.baseUrl}/v2/consumersmapper/members/users/search`;
    const httpOptions = { headers: new HttpHeaders(this.defaultHeaders) };

    return this.http.post<Array<Member>>(url, filters, httpOptions)
      .pipe(
        tap(data => { this.members = data; })
      );
  }

  /**
   * Sends a request to get countries
   */
  getCountries(): Observable<any> {
    return this.http.get<any[]>(environment.countriesJson)
      .pipe(
        tap(data => {
          let countries = [];
          data.forEach(element => {
            element.bu.forEach(bu => {
              countries.push(...bu.country);
            });
          });
          countries = countries.map(item => {
            return {
              key: item.code,
              value: item.name,
            };
          });

          countries.sort(function(a, b) {
            var nameA = a.value.toUpperCase();
            var nameB = b.value.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          this.countries = countries;
        })
      );
  }

  /**
   * Sends a request to ``PUT /v2/member/consents/revoke`` API.
   *
   * This operation will inactivate/anonymize the member and revoke all his/her consents.
   */
  forgetMember(kocid: string) {
    const url = `${this.baseUrl}/v2/member/consents/revoke`;
    const httpOptions = { headers: new HttpHeaders(this.defaultHeaders) };
    const idToken = localStorage.getItem('id_token');
    return this.http.put(url, { uuid: kocid , idToken: idToken}, httpOptions);
  }

  /**
   * Sends a request to ``GET /v2/member/mydata`` API.
   *
   * This operation will request all the member data.
   * - If there was a previous request is ready, it will return a status `ready` and
   * the url of the file containing all the member data.
   * - If not, it will return success with status `processing`
   */
  requestMemberData(kocid: string) {
    const url = `${this.baseUrl}/v2/member/mydata`;
    const httpOptions = {
      headers: new HttpHeaders(this.defaultHeaders),
      params: { uuid: kocid }
    };

    return this.http.get(url, httpOptions);
  }

  updateMember(kocid: string, member: Member) {
    const url = `${this.baseUrl}/v2/privacy/members`;
    const httpOptions = { headers: new HttpHeaders(this.defaultHeaders) };
    console.log(member);
    const idToken = localStorage.getItem('id_token');
    return this.http.put(url, { memberInfo: member, uuid: kocid, idToken: idToken}, httpOptions);
  }

  hasRevokedConsents(memberConsents: Consent[], consentsFormValue: { [key: string]: boolean }) {
    // Collect all consents in the form that are false
    const notGrantedConsents = (Object.keys(consentsFormValue) || [])
      .filter(key => !consentsFormValue[key]);

    // Verify if any previous granted consent is in the list of revoked consents
    return (memberConsents || [])
      .some(memberConsent => memberConsent.granted && notGrantedConsents.includes(memberConsent.name));
  }

  hasRevokedChannelConsents(memberChannelConsents: Preference[], channelConsentsFormValue: { [key: string]: boolean }) {
    // Collect all channel consents in the form that are false
    const notGrantedChannelConsents = (Object.keys(channelConsentsFormValue) || [])
      .filter(key => !channelConsentsFormValue[key]);

    // Verify if any previous granted consent is in the list of revoked consents
    return (memberChannelConsents || [])
      .some(memberChannelConsents => memberChannelConsents.granted && notGrantedChannelConsents.includes(memberChannelConsents.name));
  }

  buildUpdatedData(
    consents: { [key: string]: boolean },
    submittedData: { [key: string]: any },
    previousData: Member,
    deletedData: { [key: string]: any } = {},
    communicationPreferences: { [key: string]: boolean }
  ) {
    const result: any = {};

    Object.keys(submittedData).forEach(key => {
      if (!submittedData[key] && !previousData[key]) {
        return;
      }

      if (!Array.isArray(submittedData[key])) {
        if (key === 'birthday') {
          submittedData[key] = submittedData[key]
            ? submittedData[key].replace('T', ' ') : '';
        }

        previousData[key] = previousData[key] || '';
        if (submittedData[key] !== previousData[key]) {
          result[key] = submittedData[key];
        }
        return;
      }

      // If attribute value is an array:

      // If emailAddresses attribute and there are deleted email addresses
      if (key === 'emailAddresses') {
        const submittedEmails = cloneDeep(submittedData[key]);
        const deletedEmails = [];
        if (deletedData[key]) {
          // Verify if deleted items were added again
          deletedData[key].forEach(deletedItem => {
            const matchedIndex = findIndex(submittedEmails, item => item.email === deletedItem.email);

            if (matchedIndex >= 0) {
              const matchedItem = submittedEmails[matchedIndex];
              remove(submittedEmails, matchedIndex);
              // If submitted value is the same as of deleted item, reuse Id
              matchedItem.emailUniqueId = deletedItem.emailUniqueId;
              deletedEmails.push(matchedItem);

            } else {
              // Else clean values of deleted item
              // Cleaning value and leaving emailUniqueId and primaryIndicator will erase item from array in Aurora
              deletedItem.email = '';
              deletedItem.primaryIndicator = '';
              deletedEmails.push(deletedItem);
            }
          });
        }

        const nonDeletedEmails = filter(
          submittedEmails,
          submittedItem => !submittedItem.emailUniqueId
            || !deletedEmails.map(d => d.emailUniqueId).includes(submittedItem.emailUniqueId)
        );

        let changedEmails = [].concat(nonDeletedEmails, deletedEmails);
        changedEmails = differenceWith(changedEmails, previousData[key], (changedVal, previousVal) => {
          previousVal.email = previousVal.email || '';
          previousVal.primaryIndicator = previousVal.primaryIndicator || '';

          return isEqual(changedVal, previousVal);
        });

        if (changedEmails.length > 0) {
          result[key] = changedEmails;
        }
      }

      if (key === 'addresses') {
        const submittedAddresses = cloneDeep(submittedData[key]);
        const deletedAddresses = [];
        if (deletedData[key]) {
          // Clean values of deleted items and add to the submitted addresses
          deletedData[key].forEach(deletedItem => {
            // Only addressId and addressType must not be cleaned
            deletedItem.city = '';
            deletedItem.country = '';
            deletedItem.postalCode = '';
            deletedItem.state = '';
            deletedItem.streetAddress1 = '';
            deletedItem.streetAddress2 = '';
            deletedAddresses.push(deletedItem);
          });
        }

        let changedAddresses = [].concat(submittedAddresses, deletedAddresses);
        changedAddresses = differenceWith(changedAddresses, previousData[key], (changedVal, previousVal) => {
          previousVal.city = previousVal.city || '';
          previousVal.country = previousVal.country || '';
          previousVal.postalCode = previousVal.postalCode || '';
          previousVal.state = previousVal.state || '';
          previousVal.streetAddress1 = previousVal.streetAddress1 || '';
          previousVal.streetAddress2 = previousVal.streetAddress2 || '';

          return isEqual(changedVal, previousVal);
        });

        if (changedAddresses.length > 0) {
          result[key] = changedAddresses;
        }
      }

      if (key === 'communicationPreferences') {
        const submittedCommunicationPreferences = cloneDeep(submittedData[key]);
        const deletedCommunicationPreferences = [];
        if (deletedData[key]) {
          // Clean values of deleted items and add to the submitted channels
          deletedData[key].forEach(deletedItem => {
            // Only addressId and addressType must not be cleaned
            deletedItem.granted = '';
            deletedCommunicationPreferences.push(deletedItem);
          });
        }

        let changedCommunicationPreferences = [].concat(submittedCommunicationPreferences, deletedCommunicationPreferences);
        changedCommunicationPreferences = differenceWith(changedCommunicationPreferences, previousData[key], (changedVal, previousVal) => {
          previousVal.granted = previousVal.granted || '';

          return isEqual(changedVal, previousVal);
        });

        if (changedCommunicationPreferences.length > 0) {
          result[key] = changedCommunicationPreferences;
        }
      }

      if (key === 'phoneNumbers') {
        const submittedPhoneNumbers = cloneDeep(submittedData[key]);
        const deletedPhoneNumbers = [];
        if (deletedData[key]) {
          // Clean values of deleted items and add to the submitted addresses
          deletedData[key].forEach(deletedItem => {
            // Only phoneNumberId and phoneType must not be cleaned
            deletedItem.phoneNumber = '';
            deletedItem.countryCode = '';
            deletedItem.primaryIndicator = '';
            deletedPhoneNumbers.push(deletedItem);
          });
        }

        let changedPhoneNumbers = [].concat(submittedPhoneNumbers, deletedPhoneNumbers);
        changedPhoneNumbers = differenceWith(changedPhoneNumbers, previousData[key], (changedVal, previousVal) => {
          previousVal.phoneNumber = previousVal.phoneNumber || '';
          previousVal.countryCode = previousVal.countryCode || '';
          previousVal.primaryIndicator = previousVal.primaryIndicator || '';

          return isEqual(changedVal, previousVal);
        });

        if (changedPhoneNumbers.length > 0) {
          result[key] = changedPhoneNumbers;
        }
      }

      // TODO: Implement remove of SMS after global team allows its update (ACDS-1660).
      //       dynamic-form-arrray.component.ts also needs to handle SMS.
      //       Because it doesn't have an id attribute, SMS is ignored.

      if (key === 'socialDomain') {
        const submittedSocialDomain = cloneDeep(submittedData[key]);
        const deletedSocialDomain = [];
        if (deletedData[key]) {
          result[key] = [];
          // Clean values of deleted items and add to the submitted addresses
          deletedData[key].forEach(deletedItem => {
            // Only phoneNumberId and phoneType must not be cleaned
            deletedItem.domain = '';
            deletedItem.identifier = '';
            deletedItem.userName = '';
            deletedSocialDomain.push(deletedItem);
          });
        }

        let changedSocialDomain = [].concat(submittedSocialDomain, deletedSocialDomain);
        changedSocialDomain = differenceWith(changedSocialDomain, previousData[key], (changedVal, previousVal) => {
          previousVal.domain = previousVal.domain || '';
          previousVal.identifier = previousVal.identifier || '';
          previousVal.userName = previousVal.userName || '';
          return isEqual(changedVal, previousVal);
        });

        if (changedSocialDomain.length > 0) {
          result[key] = changedSocialDomain;
        }
      }
    });

    // Convert consents to array of Consents object
    result.consents = (Object.entries(consents)
      .map(consent => ({ name: consent[0], granted: consent[1] })) as Consent[]);

    result.communicationPreferences = (Object.entries(communicationPreferences)
      .map(communicationPreferences => ({ name: communicationPreferences[0], granted: communicationPreferences[1] })) as Preference[]);

    return result;
  }
}
