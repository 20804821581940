import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AwsSignerService } from '../aws/aws-signer.service';

@Injectable()
export class AwsSignerInterceptor implements HttpInterceptor {

  constructor(private awsSignerService: AwsSignerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.get('x-aws-signer')) {
      return next.handle(req);
    }

    // Delete custom header that allow this interceptor to work
    req.headers.delete('x-aws-signer');

    const awsHeader = this.awsSignerService.getAwsHeader(req.urlWithParams, req.method, req.body);

    // Default interceptor
    let httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    // Set aws headers
    httpHeaders = httpHeaders.set('x-api-key', awsHeader['x-api-key'])
      .set('X-Amz-Date', awsHeader['X-Amz-Date'])
      .set('Authorization', awsHeader['Authorization'])
      .set('X-Amz-Security-Token', awsHeader['X-Amz-Security-Token']);

    const transformedReq = req.clone({
      headers: httpHeaders
    });

    // send cloned request with altered header to the next handler.
    return next.handle(transformedReq);
  }
}
