import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ArrayControl } from '../controls/array-control';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { GroupControl } from '../controls/group-control';
import { DynamicFormControlService } from '../dynamic-form-control.service';

@Component({
  selector: 'app-dynamic-form-array',
  templateUrl: './dynamic-form-array.component.html',
  styleUrls: ['./dynamic-form-array.component.scss']
})
export class DynamicFormArrayComponent implements OnInit {
  @Input() control: ArrayControl;
  @Input() form: FormArray;
  @Output() deletedItem = new EventEmitter<{ key: string, value: any }>();

  get validationErrors() {
    return Object.entries(this.form.errors);
  }

  groupControl: GroupControl;

  constructor(private dynamicFormControlService: DynamicFormControlService) { }

  ngOnInit() {
    this.groupControl = this.control.controls[0];
  }

  addItem() {
    const newItem = this.dynamicFormControlService
      .toFormGroup(this.groupControl.controls);
    this.form.push(newItem);

    setTimeout(() => {
      const toolbarElement = document.getElementById('app-toolbar');
      const newElement = document.getElementById(`${this.control.key}_${this.form.controls.length - 1}`);
      let additionalTopHeight = 0;
      if (toolbarElement) {
        additionalTopHeight = toolbarElement.offsetHeight;
      }
      window.scroll({ top: newElement.offsetTop - additionalTopHeight, behavior: 'smooth' });
    });
  }

  deleteItem(value: any, index: number) {
    if (value[this.control.idKey]) {
      this.deletedItem.emit({ key: this.control.key, value });
    }
    this.form.removeAt(index);
  }
}
