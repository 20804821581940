import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap, map } from 'rxjs/operators';
import { LegalBasis } from './legal-bases-attributes';

@Injectable({
  providedIn: 'root'
})
export class LegalBasesService {

  private baseUrl: string;
  private defaultHeaders: any;

  // Progress bar
  loading$: BehaviorSubject<boolean>;

  legalBases: LegalBasis[];

  constructor(private http: HttpClient) {
    this.loading$ = new BehaviorSubject(false);
    this.baseUrl = environment.cdsAPI.baseUrl;
    this.defaultHeaders = {
      'Content-Type': 'application/json',
      'x-aws-signer': 'true'
    };
  }

  loadLegalBases() {
    if (this.legalBases) {
      return of(this.legalBases);
    }

    const url = `${this.baseUrl}/v4/legalbases`;
    const httpOptions = { headers: new HttpHeaders(this.defaultHeaders) };

    return this.http.get<{ legalBases: LegalBasis[] }>(url, httpOptions)
      .pipe(
        tap(data => {
          this.legalBases = data.legalBases;
        }),
        map(data => data.legalBases)
      );
  }
}
