import { BaseControl, ControlType } from './base-control';

/**
 * TextboxControl supports multiple HTML5 types such as text, email, and url via the type property.
 */
export class TextboxControl extends BaseControl<string> {
  controlType = 'textbox';
  type: string;
  bottomhint: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.bottomhint = options['bottomhint'] || '';
  }
}
