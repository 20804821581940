import { ValidatorFn } from '@angular/forms';

// Default value for input maxLength: https://www.w3schools.com/tags/att_input_maxlength.asp
const DEFAULT_MAXLENGTH = 524288;

export enum ControlType {
  Dropdown = 'dropdown',
  Textbox = 'textbox',
  Datepicker = 'datepicker',
  DateCreation = 'datecreation',
  Group = 'group',
  Array = 'array',
  Checkbox = 'checkbox'
}

export class BaseControl<T> {
  value: T;
  key: string;
  label?: string;
  required?: boolean;
  order?: number;
  controlType: string;
  controls?: BaseControl<any>[];
  readOnly?: boolean;
  hidden?: boolean;
  maxLength?: number;
  validators?: ValidatorFn[];
  hint?: string;
  canTriggerHintDef?: boolean;

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    readOnly?: boolean,
    hidden?: boolean,
    maxLength?: number,
    validators?: ValidatorFn[],
    hint?: string,
    canTriggerHintDef?: boolean,
  } = {}, controls?: BaseControl<any>[]) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType;
    this.readOnly = options.readOnly || false;
    this.hidden = options.hidden || false;
    this.controls = controls;
    this.maxLength = options.maxLength || DEFAULT_MAXLENGTH;
    this.validators = options.validators || [];
    this.hint = options.hint || '';
    this.canTriggerHintDef = options.canTriggerHintDef || false;
  }
}
