import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  backPath$: BehaviorSubject<string>;
  // Progress bar
  loading$: BehaviorSubject<boolean>;

  constructor() {
    this.backPath$ = new BehaviorSubject('');
    this.loading$ = new BehaviorSubject(false);
  }

  /**
   * This method sets the path to be redirected when clicking in the back icon button in the left side
   * of the toolbar.
   *
   * If the value is '', the toolbar will not show the back button.
   * @param value the path to be redirected.
   */
  configureBackPath(value: string) {
    this.backPath$.next(value);
  }

  isLoading(value: boolean) {
    this.loading$.next(value);
  }
}
